// @ts-nocheck
import {
  Document,
  Image,
  PDFViewer,
  Page,
  Text,
  View,
} from "@react-pdf/renderer";
import Loader from "components/Loader";
import {AppColors} from "globals/appcolors";
import {newInstance} from "globals/axiosConstants";
import {capitalizeWord} from "globals/helpers";
import {useFetchAssessmentDetails} from "hooks/interview/useFetchAssessmentDetails";
import React, {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {reportCardStyles} from "./reportCardStyles";

export const ReportCard: React.FC = () => {
  const [searchParams] = useSearchParams();

  const [assessmentData, setAssessmentData] = useState({});
  const [resumeData, setResumeData] = useState({});
  const [sentimentData, setSetsentimentData] = useState({});
  const [candidateName, setCandidateName] = useState<string>("");
  const [candidateEmail, setCandidateEmail] = useState<string>("");
  const [allSkills, setAllSkills] = useState<string[]>([]);

  const assesmentId = searchParams.get("assessment");
  const aiId = searchParams.get("aiId");

  const {refetch: getAssessmentDetails} =
    useFetchAssessmentDetails(assesmentId);

  const fetchAssessmentDetails = () => {
    getAssessmentDetails().then(async (res) => {
      if (res.isError === false) {
        if (res.data !== undefined) {
          const {resume_url, main_question_id} = res.data.data.assessment;
          const {
            user: {first_name, last_name, email},
          } = res.data.data.assessment.candidate;
          const evaluationbody = new FormData(),
            parseResumeBody = new FormData();
          evaluationbody.append("main_question_id", main_question_id);
          parseResumeBody.append("s3_url", resume_url);
          const assessmentData = await newInstance.get(
            `/get-assessment/${aiId}`
          );
          setAssessmentData(assessmentData.data);
          const evaluationResponse = await newInstance.post(
            `/calculate-evaluation`,
            evaluationbody
          );
          const parsedResumeResponse = await newInstance.post(
            "/resume-parse",
            parseResumeBody
          );
          setResumeData(parsedResumeResponse.data);
          setSetsentimentData(evaluationResponse.data);
          setCandidateName(
            `${capitalizeWord(first_name)} ${capitalizeWord(last_name)}`
          );
          setCandidateEmail(email);
          const {
            resume_content: {skills},
          } = parsedResumeResponse.data;
          const temp = [];
          if (Object.values(skills).length > 0) {
            Object.values(skills).forEach((skillsArr) => {
              skillsArr.forEach((skill) => temp.push(skill));
            });
            setAllSkills(temp);
          }
        }
      }
    });
  };

  const {resume_content: {experience = [], introduction, title = ""} = {}} =
    resumeData;

  const {main_question: {questions = []} = {}} = assessmentData;

  const {total} = sentimentData;

  useEffect(() => {
    fetchAssessmentDetails();
  }, []);

  return Object.keys(sentimentData).length > 0 &&
    Object.keys(resumeData).length > 0 &&
    Object.keys(assessmentData).length > 0 ? (
    <PDFViewer showToolbar={true} style={{height: "100vh"}}>
      <Document>
        <Page size="A4" style={reportCardStyles.page}>
          <View fixed style={reportCardStyles.watermark}>
            <Image
              source={
                "https://images.ctfassets.net/7oc31naqqojs/2anCoDyKXuDOb4MsNCpZXw/d6cf3e39be14d8ef234e9b33948e85f6/Sourcebae-logo.jpg"
              }
            />
          </View>

          <View style={{marginTop: -150, position: "relative"}}>
            <View>
              <Image
                source={
                  "https://images.ctfassets.net/7oc31naqqojs/10EnuqQ9u2BX8EZSXBKWOY/bfa45f529c18f9af3a1ae40e4bf3f1c6/Sourcebae-logo-removebg-preview.png"
                }
                style={{width: 130, height: 40}}
              />
            </View>
            <View
              style={[
                reportCardStyles.row,
                {justifyContent: "space-between", marginTop: 15},
              ]}
            >
              <View>
                <Text style={reportCardStyles.name}>{candidateName}</Text>
                <Text style={reportCardStyles.title}>{title}</Text>
              </View>

              <View>
                <Text
                  style={reportCardStyles.lightText}
                >{`Email - ${candidateEmail}`}</Text>
              </View>
            </View>

            <View style={reportCardStyles.section}>
              <Text style={reportCardStyles.titleText}>Introduction</Text>
              <Text style={reportCardStyles.descriptiveText}>
                {introduction}
              </Text>
            </View>

            <View style={reportCardStyles.section}>
              <Text style={reportCardStyles.titleText}>
                Experience & Projects
              </Text>
              {experience &&
                Array.isArray(experience) &&
                experience.map(
                  ({company, duration, position, responsibilities}, index) => {
                    return (
                      <View style={{marginTop: 10, marginLeft: 5}} key={index}>
                        <Text
                          style={[
                            reportCardStyles.descriptiveText,
                            {fontWeight: "bold"},
                          ]}
                        >
                          {`${index + 1}. ${company} (${duration})`}
                        </Text>
                        <Text
                          style={[
                            reportCardStyles.descriptiveText,
                            {paddingLeft: 15},
                          ]}
                        >
                          {position}
                        </Text>
                        {responsibilities.map(
                          (responsibility, responsibilityIndex) => (
                            <Text
                              style={[
                                reportCardStyles.descriptiveText,
                                {paddingLeft: 25},
                              ]}
                            >{`${
                              responsibilityIndex + 1
                            }. ${responsibility}`}</Text>
                          )
                        )}
                      </View>
                    );
                  }
                )}
            </View>

            <View
              style={{
                backgroundColor: "#cecece",
                height: 1,
                width: "100%",
                marginTop: 30,
              }}
            ></View>

            <View style={reportCardStyles.section}>
              <Text style={reportCardStyles.titleText}>Expertise</Text>
              {allSkills.map((e, index) => (
                <View style={{marginLeft: 15, marginTop: 5}} key={e}>
                  <Text style={reportCardStyles.descriptiveText} key={e}>
                    {`${index + 1}. ${e}`}
                  </Text>
                </View>
              ))}
            </View>

            <View
              style={{
                backgroundColor: "#cecece",
                height: 1,
                width: "100%",
                marginTop: 30,
              }}
            ></View>

            <View>
              {questions.map(
                ({questionAnswer, questionText, s3_url}, index) => (
                  <View style={{marginTop: 20, flexDirection: "row"}}>
                    <View
                      style={{
                        minWidth: "60%",
                        maxWidth: "60%",
                      }}
                    >
                      <Text style={reportCardStyles.titleText}>{`Question - ${
                        index + 1
                      }`}</Text>
                      <Text
                        style={[
                          reportCardStyles.titleText,
                          {
                            fontSize: 11,
                            marginTop: 8,
                            fontWeight: "bold",
                            color: AppColors.BLACK,
                          },
                        ]}
                      >
                        {questionText}
                      </Text>
                      <Text
                        style={{
                          fontWeight: "bold",
                          fontSize: 11,
                          marginTop: 10,
                        }}
                      >
                        {`Answer -`}
                        <Text
                          style={[
                            reportCardStyles.descriptiveText,
                            {color: "#6f6f6f"},
                          ]}
                        >{` ${
                          questionAnswer === "" ? "N.A." : questionAnswer
                        }`}</Text>
                      </Text>
                    </View>

                    {s3_url && s3_url.length > 0 && (
                      <View style={{width: "40%", alignItems: "flex-end"}}>
                        <Image
                          source={s3_url[0]}
                          style={{width: "80%", borderRadius: 5}}
                        />
                      </View>
                    )}
                  </View>
                )
              )}
            </View>

            <View
              style={{
                backgroundColor: "#cecece",
                height: 1,
                width: "100%",
                marginTop: 30,
              }}
            ></View>

            <View style={{marginTop: 30}}>
              <Text style={reportCardStyles.titleText}>OVERALL RATING</Text>
              <View style={{flexDirection: "row", marginTop: 20}}>
                <View style={{minWidth: "30%"}}>
                  <View>
                    <Text style={reportCardStyles.heading}>Accuracy</Text>
                    <Text
                      style={{
                        color: "rgba(178, 58, 255, 1)",
                        fontSize: 40,
                        marginTop: 4,
                        fontWeight: "extrabold",
                        letterSpacing: 2,
                      }}
                    >{`${Number(total?.total_accuracy)}%`}</Text>
                  </View>
                </View>

                <View style={{minWidth: "70%"}}>
                  <Text style={reportCardStyles.heading}>Skill Scores</Text>
                  {Object.keys(total).map((sentiment) => {
                    return (
                      <View style={{marginTop: 15}}>
                        <Text style={reportCardStyles.heading}>
                          {sentiment
                            .split("_")
                            .map((e) => capitalizeWord(e))
                            .join(" ")}
                        </Text>
                        <View
                          style={{
                            position: "relative",
                            width: "80%",
                            height: 6,
                            backgroundColor: "white",
                            borderRadius: 4,
                            marginTop: 5,
                          }}
                        >
                          <View
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              height: "100%",
                              width: `${Number(total[sentiment])}%`,
                              backgroundColor: "rgba(178, 58, 255, 1)",
                              borderRadius: "100%",
                            }}
                          ></View>
                          <View
                            style={{
                              position: "absolute",
                              left: "102%",
                              top: -5,
                            }}
                          >
                            <Text style={reportCardStyles.heading}>{`${Number(
                              total[sentiment]
                            )}%`}</Text>
                          </View>
                        </View>
                      </View>
                    );
                  })}
                </View>
              </View>
            </View>

            {/* <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginTop: 60,
                alignSelf: "flex-end",
                opacity: 0.7,
              }}
            >
              <Text
                style={[
                  reportCardStyles.descriptiveText,
                  {fontSize: 12, marginRight: 5, fontWeight: "bold"},
                ]}
              >
                Report generated by
              </Text>
              <Image
                source={
                  "https://images.ctfassets.net/7oc31naqqojs/2DHgcYhrgutZvZaAXDHoqc/cca98c81966d5f3560f99fe09b20efd0/Frame_1686552541.png"
                }
                style={{width: 25, height: 30}}
              />
            </View> */}
          </View>
        </Page>
      </Document>
    </PDFViewer>
  ) : (
    <Loader />
  );
};

import {useAppCommonDataProvider} from "components/AppCommonDataProvider";
import ViewButton from "components/Containers/Button/Button/ViewButton";
import {AssessmentForcefullyFinished} from "components/Popups/AssessmentForcefullyFinished";
import strings from "globals/constants/strings.json";
import {createBrowserHistory} from "history";
import {Images} from "Images";
import {useEffect, useState} from "react";
import styles from "./Interview.module.scss";

const InterviewThankYouScreen: React.FC = () => {
  const history = createBrowserHistory();
  const [loading, setloading] = useState<boolean>(false);

  const {testForcefullyFinished, setTestForcefullyFinished} =
    useAppCommonDataProvider();

  useEffect(() => {
    history.listen(({location, action}) => {
      console.log("action", action);
      if (action === "PUSH") {
        // history.replace("/");
        window.location.reload();
      }
      // anchorRef.current?.click()
      // window.location.href = '//www.google.com'
      // window.op
      // history.push({
      //   pathname: currentLocation.pathname,
      //   search: location.search
      // })
      // else history.replace("/");
      else window.location.reload();
      // anchorRef.current?.click()
      // history.go(1)
    });
  }, []);

  const onFinish = () => {
    window.location.assign(`https://sourcebae.com`);
  };

  return (
    <div className={styles.thankyou__container}>
      <div className={styles.thankyou__wrapper}>
        <img
          src={Images.InterviewFinish}
          alt="ThankYou"
          className={styles.thankyou__image}
        />
        <p className={styles.thankyou__title}>
          {strings.interview.finish.title}
        </p>
        <p className={styles.thankyou__subtext}>
          {strings.interview.finish.subtext}
          <span
            className="text-primary font-bold cursor-pointer"
            onClick={onFinish}
          >{` ${strings.interview.finish.survey}.`}</span>
        </p>
        <ViewButton
          loading={loading}
          invert
          name="Visit Sourcebae"
          onClick={onFinish}
          style={{paddingInline: "50px", height: "50px", marginTop: 20}}
        />
      </div>
      {testForcefullyFinished === true && (
        <AssessmentForcefullyFinished
          open={testForcefullyFinished}
          onClose={() => setTestForcefullyFinished?.(false)}
        />
      )}
    </div>
  );
};

export default InterviewThankYouScreen;

import {useAppCommonDataProvider} from "components/AppCommonDataProvider";
import {ChatProvider} from "components/ChatProvider";
import {GenericError} from "components/InterviewPermissions/GenericError";
import {SystemDeniedPermissions} from "components/InterviewPermissions/SystemDeniedPermissions";
import {TrackError} from "components/InterviewPermissions/TrackError";
import {UserDeniedPermissions} from "components/InterviewPermissions/UserDeniedPermissions";
import {EnterFullScreenAlert} from "components/Popups/EnterFullScreenAlert";
import {ScreenShareStopped} from "components/Popups/ScreenShareStopped";
import {SecondRoundCompleted} from "components/Popups/SecondRoundCompleted";
import {SecondRoundFinishConfirmation} from "components/Popups/SecondRoundFinishConfirmation";
import {SecondRoundMismatch} from "components/Popups/SecondRoundMismatch";
import {TabSwitchedAlert} from "components/Popups/TabSwitchedAlert";
import {Row} from "components/Row";
import {AppColors} from "globals/appcolors";
import {COOKIESKEYS} from "globals/constants/cookiesKeys";
import {RouteLinks} from "globals/constants/routeLinks";
import {getElementDimensions} from "globals/helpers";
import {DialogType, SecondRoundQuestion} from "globals/types/globalTypes";
import {useFetchAssessmentDetails} from "hooks/interview/useFetchAssessmentDetails";
import {useUpdateAssessment} from "hooks/interview/useUpdateAssessment";
import {
  MediaPermissionsError,
  MediaPermissionsErrorType,
  requestMediaPermissions,
} from "mic-check";
import moment, {Moment} from "moment";
import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {Bounce, Spinner} from "react-activity";
import {useFullScreenHandle} from "react-full-screen";
import {StatusMessages, useReactMediaRecorder} from "react-media-recorder";
import Modal from "react-responsive-modal";
import {useNavigate, useSearchParams} from "react-router-dom";
import {SecondRoundInterviewFlow} from "./SecondRoundInterviewFlow";

const modalContainerStyles: CSSProperties | undefined = {
  background: AppColors.WHITE,
  borderRadius: 15,
  padding: "1.5rem 2rem",
  maxWidth: "60vw",
  maxHeight: "70vh",
  overflowY: "scroll",
};

export const SecondRoundInterview: React.FC = () => {
  const [queryParams] = useSearchParams();
  const assessmentId = queryParams.get("assessment");

  const voice = new SpeechSynthesisUtterance();

  const previousRecorderStatus = useRef<StatusMessages | null>(null);
  const tabSwitchCount = useRef<number>(0);
  const tabSwitchStartTime = useRef<Moment | null>(null);
  const timerId = useRef<NodeJS.Timer | undefined>(undefined);

  const {startRecording, mediaBlobUrl, stopRecording, status, muteAudio} =
    useReactMediaRecorder({
      video: false,
      screen: true,
      audio: false,
    });
  const handle = useFullScreenHandle();
  const navigate = useNavigate();

  const {mutateAsync: updateAssessment} = useUpdateAssessment(assessmentId!);
  const {refetch: getAssessmentDetails} = useFetchAssessmentDetails(
    assessmentId!
  );
  const {setPermissions, permissions, setInterview_details} =
    useAppCommonDataProvider();

  const [videoConstraints, setVideoConstraints] =
    useState<MediaTrackConstraints>({
      width: 0,
      height: 0,
    });
  const [screenShareDenied, setScreenShareDenied] = useState<boolean>(false);
  const [fullScreenParameters, setFullScreenParameters] = useState<{
    didUserPrompt: boolean;
    showModal: boolean;
    changeCount: number;
  }>({
    didUserPrompt: false,
    showModal: false,
    changeCount: 0,
  });
  const [userSwitchedTabs, setUserSwitchedTabs] = useState<boolean>(false);
  const [
    countdownRemainingTimeAfterTabSwitch,
    setCountdownRemainingTimeAfterTabSwitch,
  ] = useState<number>(0);
  const [showFinishModal, setShowFinishModal] = useState<boolean>(false);
  const [isTestFinishing, setIsTestFinishing] = useState<boolean | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [questions, setQuestions] = useState<SecondRoundQuestion[]>([]);
  const [renderable, setRenderable] = useState<boolean>(true);
  const [isPermissionsModalOpen, setIsPermissionsModalOpen] =
    useState<boolean>(false);
  const [permissionError, setPermissionError] = useState<DialogType | null>(
    null
  );
  const [presetting, setPresetting] = useState<boolean>(true);
  const [systemMismatch, setSystemMismatch] = useState<boolean>(false);
  const [isAssessmentComplete, setIsAssessmentComplete] =
    useState<boolean>(false);

  const showDialogRef = useRef<DialogType | null>(permissionError);
  showDialogRef.current = permissionError;

  function handleScreenShareDenied() {
    if (
      (previousRecorderStatus.current === "acquiring_media" &&
        status === "idle") ||
      (status === "stopped" && isTestFinishing === false)
    ) {
      setScreenShareDenied(true);
    }
    if (status === "recording") {
      setScreenShareDenied(false);
      setIsTestFinishing(false);
      resizeVideo();
    }

    previousRecorderStatus.current = status;
  }

  function restartSession() {
    startRecording();
  }

  function finishTest() {
    updateAssessment({
      status: 14,
    }).then(() => {
      setLoading(false);
      navigate(`/${RouteLinks.Interview_Finish}`);
    });
  }

  function handleTabSwitch(e: Event) {
    if (document.visibilityState === "hidden") {
      if (tabSwitchCount.current === 4) {
        startUpload();
      } else {
        e.preventDefault();
        window.dispatchEvent(new Event("tab_switched"));

        voice.text =
          "Please return back to your screen before the assessment is automatically finished";
        window.speechSynthesis.speak(voice);
        setFullScreenParameters({
          showModal: false,
          didUserPrompt: true,
          changeCount: fullScreenParameters.changeCount,
        });
        handle.exit();
        tabSwitchCount.current += 1;
        setUserSwitchedTabs(true);
        tabSwitchStartTime.current = moment();
        startCountdown();
      }
    }
  }

  function startCountdown() {
    timerId.current = setInterval(() => {
      const remainingTime = moment().diff(
        tabSwitchStartTime.current,
        "seconds"
      );
      setCountdownRemainingTimeAfterTabSwitch(remainingTime);
      if (remainingTime === 45) {
        clearInterval(timerId.current);
        startUpload();
      }
    }, 1000);
  }

  function handleTabSwitchModalClose() {
    enterFullScreen();
    timerId.current = undefined;
    tabSwitchStartTime.current = null;
    setUserSwitchedTabs(false);
    window.dispatchEvent(new Event("tab_switch_reverted"));
  }

  function enterFullScreen() {
    timerId.current = undefined;
    tabSwitchStartTime.current = null;
    setUserSwitchedTabs(false);
    handle.enter();
    setFullScreenParameters({
      didUserPrompt: false,
      showModal: false,
      changeCount: fullScreenParameters.changeCount + 1,
    });
  }

  function handleFinishConfirmationClose() {
    handle.enter();
    setShowFinishModal(false);
  }

  function handleResize() {
    resizeVideo();
  }

  function resizeVideo() {
    const dimensions = getElementDimensions(
      document.getElementsByClassName("webcam__container")[0]
    );
    if (dimensions !== null) {
      const {height, width} = dimensions;
      setVideoConstraints({width, height});
    }
  }

  async function uploadSessionForProcessing(url: string) {
    setLoading(true);
    finishTest();
  }

  function startUpload() {
    setIsTestFinishing(true);
    stopRecording();
  }

  function checkForExplanationDialog() {
    if (
      (!permissions?.camera || !permissions.microphone) &&
      showDialogRef.current === null
    )
      setPermissionError(DialogType.explanation);
  }

  async function checkPermissions() {
    if (!("navigator" in window)) {
      setIsPermissionsModalOpen(false);
      setRenderable(false);
    } else {
      requestMediaPermissions()
        .then(() => {
          setIsPermissionsModalOpen(false);
          setPermissions?.({
            ...permissions,
            camera: true,
            microphone: true,
          });
        })
        .catch((e: MediaPermissionsError) => {
          setIsPermissionsModalOpen(true);
          const {type} = e;
          if (type === MediaPermissionsErrorType.SystemPermissionDenied)
            setPermissionError(DialogType.systemDenied);
          else if (type === MediaPermissionsErrorType.UserPermissionDenied)
            setPermissionError(DialogType.userDenied);
          else if (type === MediaPermissionsErrorType.CouldNotStartVideoSource)
            setPermissionError(DialogType.trackError);
          else setPermissionError(DialogType.explanation);
        })
        .finally(() => setPresetting(false));

      setTimeout(() => checkForExplanationDialog(), 500);
    }
  }

  const handleFullScreenChange = useCallback(
    (state: boolean) => {
      if (!state && !fullScreenParameters.didUserPrompt)
        setFullScreenParameters({
          didUserPrompt: true,
          showModal: true,
          changeCount:
            fullScreenParameters.changeCount &&
            fullScreenParameters.changeCount + 1,
        });
      else
        setFullScreenParameters({
          didUserPrompt: false,
          showModal: false,
          changeCount: fullScreenParameters.changeCount + 1,
        });
    },
    [handle]
  );

  const renderSystemDeniedPermissions = () => <SystemDeniedPermissions />;

  const renderUserDeniedPermissions = () => <UserDeniedPermissions />;

  const renderTrackError = () => <TrackError />;

  const renderGenericError = () => <GenericError />;

  const renderDialogContent = () => {
    switch (permissionError) {
      case DialogType.systemDenied:
        return renderSystemDeniedPermissions();
      case DialogType.userDenied:
        return renderUserDeniedPermissions();
      case DialogType.trackError:
        return renderTrackError();
      case DialogType.explanation:
        return renderGenericError();
    }
  };

  useEffect(() => {
    muteAudio();
    checkPermissions();
  }, []);

  useEffect(() => {
    if (isTestFinishing === true && mediaBlobUrl !== undefined) {
      uploadSessionForProcessing(mediaBlobUrl);
    }
  }, [mediaBlobUrl]);

  useEffect(() => {
    handleScreenShareDenied();
  }, [status]);

  useEffect(() => {
    if (
      presetting === false &&
      isPermissionsModalOpen === false &&
      systemMismatch === false
    ) {
      const questions = localStorage.getItem(COOKIESKEYS.ROUND_TWO_QUESTIONS);
      if (questions === null) {
        setSystemMismatch(true);
      } else {
        getAssessmentDetails().then((res) => {
          if (res.data) {
            console.log("i have data", res.data);
            setInterview_details?.(res.data);
            const {data} = res.data;
            const {assessment} = data;
            const {status} = assessment;

            if (status === 14) setIsAssessmentComplete(true);
            else {
              setRenderable(true);
              startRecording();
            }
          }
        });
        window.onbeforeunload = () => true;
        document.addEventListener("visibilitychange", handleTabSwitch);
        window.addEventListener("resize", handleResize);

        const interviewQuestions: SecondRoundQuestion[] = (
          JSON.parse(questions) as SecondRoundQuestion[]
        ).map((question) => ({
          ...question,
          question: question.question,
        }));
        setQuestions(interviewQuestions);
      }
    }

    return () => {
      stopRecording();
      window.onbeforeunload = () => null;
      document.removeEventListener("visibilitychange", handleTabSwitch);
      window.removeEventListener("resize", handleResize);
      window.speechSynthesis.cancel();
    };
  }, [presetting, isPermissionsModalOpen, systemMismatch]);

  if (isAssessmentComplete)
    return (
      <SecondRoundCompleted
        open
        onClose={() => {
          window.open("about:blank", "_self");
          window.close();
        }}
      />
    );
  else if (renderable === true) {
    if (presetting === true)
      return (
        <Row classNames="h-screen w-screen justify-center">
          <Bounce color={AppColors.PRIMARYCOLOR} size={40} />
        </Row>
      );
    else {
      if (isPermissionsModalOpen === true)
        return (
          <Modal
            open={isPermissionsModalOpen === true}
            center
            onClose={() => {}}
            styles={{modal: modalContainerStyles}}
            showCloseIcon={false}
          >
            {renderDialogContent()}
          </Modal>
        );

      if (systemMismatch)
        return (
          <SecondRoundMismatch
            open={systemMismatch === true}
            onClose={() => {}}
          />
        );
      else
        return status === "recording" ? (
          <>
            <ChatProvider>
              <SecondRoundInterviewFlow
                videoConstraints={videoConstraints}
                fullScreenHandle={handle}
                questions={questions}
                showTimer={isTestFinishing}
                isTestFinishing={isTestFinishing}
                handleFullScreenChange={handleFullScreenChange}
                onPressEnd={() => {
                  handle.exit();
                  setFullScreenParameters({
                    changeCount: fullScreenParameters.changeCount,
                    didUserPrompt: true,
                    showModal: false,
                  });
                  setShowFinishModal(!showFinishModal);
                }}
                stopRecording={startUpload}
              />
            </ChatProvider>

            {screenShareDenied === true && (
              <ScreenShareStopped
                open={screenShareDenied}
                onClose={() => {}}
                setUserStoppedScreenShare={setScreenShareDenied}
                startRecording={restartSession}
              />
            )}

            {userSwitchedTabs && (
              <TabSwitchedAlert
                open={userSwitchedTabs}
                onClose={handleTabSwitchModalClose}
                tabSwitchCount={tabSwitchCount.current}
                remainingTime={countdownRemainingTimeAfterTabSwitch}
              />
            )}

            {fullScreenParameters.didUserPrompt === true &&
              fullScreenParameters.showModal === true && (
                <EnterFullScreenAlert
                  open={
                    fullScreenParameters.didUserPrompt === true &&
                    fullScreenParameters.showModal === true
                  }
                  onClose={() => {}}
                  changeCount={fullScreenParameters.changeCount}
                  enterFullScreen={enterFullScreen}
                  finishTest={startUpload}
                />
              )}

            {showFinishModal === true && (
              <SecondRoundFinishConfirmation
                open={showFinishModal}
                onClose={handleFinishConfirmationClose}
                stopRecording={startUpload}
              />
            )}

            {loading && (
              <Modal
                open
                onClose={() => {}}
                center
                showCloseIcon={false}
                styles={{
                  modal: {
                    paddingBlock: "2rem",
                    paddingInline: "4rem",
                    borderRadius: 6,
                  },
                }}
              >
                <div className="flex items-center">
                  <Spinner size={30} color={AppColors.REPORTCARD_SECONDARY} />
                  <p className="ml-4 font-bold text-xl">Finishing your test</p>
                </div>
              </Modal>
            )}
          </>
        ) : null;
    }
  } else return null;
};

import Editor from "@monaco-editor/react";
import {
  Button,
  LinearProgress,
  linearProgressClasses,
  styled,
} from "@mui/material";
import {useAppCommonDataProvider} from "components/AppCommonDataProvider";
import {useChat} from "components/ChatProvider";
import {Row} from "components/Row";
import {CountDownTimer} from "components/Timer";
import {Webcam} from "components/Webcam";
import {AppColors} from "globals/appcolors";
import {emotionInstance, newInstance} from "globals/axiosConstants";
import {
  SecondRoundPerQuestionTime,
  SecondRoundScreenshotsPerQuestion,
} from "globals/constants/InterviewConstants";
import {uploadFileToS3} from "globals/helpers";
import {Language, SecondRoundQuestion} from "globals/types/globalTypes";
import {Images} from "Images";
import {Leva} from "leva";
import React, {Suspense, useEffect, useRef, useState} from "react";
import {Bounce, Windmill} from "react-activity";
import {FullScreen, FullScreenHandle} from "react-full-screen";
import {IoIosPlay} from "react-icons/io";
import {useSearchParams} from "react-router-dom";
import {io} from "socket.io-client";
import interviewCommonStyles from "./Interview.module.scss";
import styles from "./SecondRoundInterview.module.scss";

interface IProps {
  videoConstraints: MediaTrackConstraints;
  fullScreenHandle: FullScreenHandle;
  showTimer: boolean | undefined;
  questions: SecondRoundQuestion[];
  isTestFinishing: boolean | undefined;
  handleFullScreenChange: (state: boolean, handle: any) => void;
  onPressEnd: () => void;
  stopRecording: () => void;
}

const socket = io(process.env.REACT_APP_SOCKET_URL);

const BorderLinearProgress = styled(LinearProgress)(({theme}) => ({
  height: 3,
  borderRadius: 2,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "rgba(245, 247, 255, .2)",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#00D121",
  },
}));

let recorder: MediaRecorder | null = null;

const clearRecorders = () => {
  if (recorder && recorder.state !== "inactive") {
    recorder.stop();
    recorder.stream.getTracks().forEach((track) => track.stop());
    recorder = null;
  }
};

const initRecorder = async (): Promise<MediaRecorder> => {
  const stream = await navigator.mediaDevices.getUserMedia({
    audio: {echoCancellation: true, noiseSuppression: true},
  });
  const recorder = new MediaRecorder(stream, {mimeType: "audio/webm"});
  return recorder;
};

const Model = () => {
  return (
    <Row classNames="justify-center py-4">
      <img
        src="https://images.ctfassets.net/7oc31naqqojs/2DHgcYhrgutZvZaAXDHoqc/cca98c81966d5f3560f99fe09b20efd0/Frame_1686552541.png"
        alt="ai-logo"
        className="w-[5.5rem] h-24 recording-pulse"
      />
    </Row>
  );
};

export const SecondRoundInterviewFlow: React.FC<IProps> = ({
  videoConstraints,
  questions,
  fullScreenHandle,
  showTimer,
  isTestFinishing,
  handleFullScreenChange,
  onPressEnd,
  stopRecording,
}) => {
  const [queryParams] = useSearchParams();
  const assessmentId = queryParams.get("assessment");

  const {answer, setAnswer, setImages, audio, setAudio, images} = useChat();

  const {interview_details} = useAppCommonDataProvider();

  const [showIndividualQuestionTimer, setShowIndividualQuestionTimer] =
    useState<boolean>(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [pingedScreenshots, setPingedScreenshots] = useState<boolean>(true);
  const [languages, setLanguages] = useState<Language[]>([]);
  const [language, setLanguage] = useState<number>(0);
  const [code, setCode] = useState<string | undefined>("");
  const [isCodeSubmitting, setIsCodeSubmitting] = useState<boolean>(false);
  const [codeOutput, setCodeOutput] = useState<string | undefined>("");
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastString, setToastString] = useState<string>("");
  const [allQuestions, setAllQuestions] = useState<SecondRoundQuestion[]>([
    ...questions,
  ]);
  const [processingResponse, setProcessingResponse] = useState<boolean>(false);
  const [createCrossQuestion, setCreateCrossQuestion] = useState<boolean>(true);

  const currentQuestion = allQuestions[currentQuestionIndex];
  const isCodingQuestion = currentQuestion
    ? currentQuestion.__typename === "coding"
    : false;
  const progress = ((currentQuestionIndex / allQuestions.length) * 100).toFixed(
    2
  );

  const prevQuestion = useRef<SecondRoundQuestion | null>(null);

  const startRecording = async () => {
    recorder = await initRecorder();

    socket.emit("start_stream");
    recorder.start(600);
    recorder.ondataavailable = (e) => {
      socket.emit("audio_chunk", e.data);
    };
  };

  const stopAudioRecording = async (isTemporary?: boolean) => {
    if (isTemporary) {
      clearRecorders();
      return;
    }

    const documentId = interview_details?.data.assessment.main_question_id;
    const {ai_question_id} = currentQuestion;

    socket.emit("update_answer", {
      document_id: documentId,
      question_id: ai_question_id,
      answer,
      cross_question: createCrossQuestion,
    });

    clearRecorders();

    await emotionInstance
      .post("/analyze_emotion", {
        documentId,
        questionId: ai_question_id,
        s3Urls: images,
      })
      .finally(() => setImages([]));
  };

  async function handleQuestionChange() {
    if (audio) {
      audio.pause();
      audio.currentTime = 0;
    }

    if (isCodingQuestion) {
      const data = new FormData();
      data.append("answer", code ?? " ");
      data.append(
        "documentId",
        interview_details?.data.assessment.main_question_id!
      );
      data.append("questionId", currentQuestion.ai_question_id);

      newInstance.post("/coding-answers", data);
    }

    if (currentQuestionIndex + 1 === allQuestions.length) {
      stopRecording();
      return;
    }

    stopAudioRecording();

    setShowIndividualQuestionTimer(false);
    setProcessingResponse(true);

    socket.off("update_status");

    socket.on(
      "update_status",
      ({audio_file_path_mp3, cross_question, cross_question_id}) => {
        if (createCrossQuestion) {
          const newQuestion: SecondRoundQuestion = {
            __typename: null,
            ai_question_id: cross_question_id,
            animation: null,
            assessmentId: +assessmentId!,
            audio_url: audio_file_path_mp3,
            facialExpression: null,
            lipsync_data: null,
            questionAnswer: null,
            question: cross_question,
            id: Math.random(),
            isFollowUpQuestion: true,
          };
          const newQuestions = [...allQuestions];
          newQuestions.splice(currentQuestionIndex + 1, 0, newQuestion);
          setAllQuestions(newQuestions);
        }

        socket.disconnect();
        setCurrentQuestionIndex((prev) => prev + 1);
        setPingedScreenshots(false);
        setCode("");
        setAnswer?.("");
        setCreateCrossQuestion((prev) => !prev);

        setTimeout(() => {
          setPingedScreenshots(true);
          setProcessingResponse(false);

          if (!isCodeSubmitting) {
            socket.connect();
          }
        }, 10);
      }
    );
  }

  async function submitCode() {
    setIsCodeSubmitting(true);
    fetch("https://judge0-ce.p.rapidapi.com/submissions", {
      method: "POST",
      headers: {
        "x-rapidapi-key": "71f3636c91msh5328f61f4937f45p18ec70jsn74e4e77f11e3",
        "x-rapidapi-host": "judge0-ce.p.rapidapi.com",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        source_code: code,
        language_id: language,
      }),
    })
      .then((res) => res.json())
      .then(({token}) => {
        const id = setInterval(() => {
          fetch(`https://judge0-ce.p.rapidapi.com/submissions/${token}`, {
            headers: {
              "x-rapidapi-key":
                "71f3636c91msh5328f61f4937f45p18ec70jsn74e4e77f11e3",
              "x-rapidapi-host": "judge0-ce.p.rapidapi.com",
              "Content-Type": "application/json",
            },
          })
            .then((res) => res.json())
            .then((response) => {
              if (response.compile_output) {
                setShowToast(true);
                setToastString(response.compile_output);
                setTimeout(() => {
                  setShowToast(false);
                  setToastString("");
                }, 5000);
              }
              if (response.stdout) {
                setCodeOutput(response.stdout);
              }
            })
            .finally(() => {
              clearInterval(id);
              setIsCodeSubmitting(false);
            });
        }, 500);
      });
  }

  async function onCaptureScreenshot(data: any) {
    const res = await uploadFileToS3(
      data,
      interview_details?.data.assessment.ai_assessment_id + ".jpg",
      "image/jpeg",
      "base64"
    );
    // @ts-ignore
    setImages?.((prevArray) => [...prevArray, res]);
  }

  useEffect(() => {
    if (!currentQuestion) return;

    if (
      prevQuestion.current &&
      prevQuestion.current.audio_url === currentQuestion.audio_url
    )
      return;

    prevQuestion.current = currentQuestion;

    if (!isCodingQuestion) {
      const newAudio = new Audio(currentQuestion.audio_url);
      newAudio.playbackRate = 1.3;
      newAudio.play();

      newAudio.onerror = (e) => {
        console.log("eeeee", e);
      };

      newAudio.onended = () => {
        clearRecorders();
        setShowIndividualQuestionTimer(true);
        setTimeout(() => {
          startRecording();
        }, 100);
      };
      setAudio?.(newAudio);
    }
  }, [currentQuestionIndex, currentQuestion, isCodingQuestion]);

  useEffect(() => {
    setAllQuestions(questions);
  }, [questions]);

  useEffect(() => {
    socket.on("transcription", (data) => {
      setAnswer?.(data.transcript);
    });

    window.addEventListener("tab_switched", () => stopAudioRecording(true));
    window.addEventListener("tab_switch_reverted", () => startRecording());

    fetch("https://ce.judge0.com/languages/")
      .then((res) => res.json())
      .then((res) => {
        setLanguages(res as Language[]);
        setLanguage(res[0].id);
      });

    return () => {
      window.removeEventListener("tab_switched", () =>
        stopAudioRecording(true)
      );
      window.removeEventListener("tab_switch_reverted", () => startRecording());
    };
  }, []);

  return (
    <FullScreen
      className="fullScreenStyle"
      handle={fullScreenHandle}
      onChange={handleFullScreenChange}
    >
      <main className="bg-lightBlack-800 px-6 min-h-screen">
        <header className={`${interviewCommonStyles.behavior__header}`}>
          <Row classNames="text-white-200 pt-8">
            <div className="w-3/4">
              <img
                src={
                  "https://images.ctfassets.net/7oc31naqqojs/3spCipJXTTN8u8KE1uDnW4/e63c090314f340df2c2ba1bc75d09111/Sourcebae-logo.png"
                }
                alt="Logo"
                style={{width: 220, height: 70}}
              />
            </div>
          </Row>
          <div className="flex justify-end mt-4 font-extrabold text-lg text-white-200">
            {`${progress}%`}
          </div>
          <BorderLinearProgress
            variant="determinate"
            value={+progress}
            className="w-full rounded-sm mt-[12px]"
          />
        </header>

        <div className={"flex mt-16 h-70vh"}>
          <div className={styles.container}>
            <Row classNames={styles.wrapper}>
              <div className="w-full h-full flex">
                <Row classNames={styles.own__feed}>
                  <section
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    className="webcam__container relative"
                  >
                    {videoConstraints.width !== 0 && (
                      <>
                        <Row classNames="absolute top-5 left-5 z-10 bg-lightBlack-900 bg-opacity-50 py-2 px-4 rounded-sm text-white-200">
                          <img
                            src={Images.RecordingIcon}
                            className="animate-recording-pulse"
                          />{" "}
                          &nbsp; Screen Recording On
                        </Row>
                        <Webcam
                          className="min-w-full h-full"
                          pingedScreenshots={pingedScreenshots}
                          onCaptureScreenshot={onCaptureScreenshot}
                          pingInterval={
                            (SecondRoundPerQuestionTime /
                              SecondRoundScreenshotsPerQuestion) *
                            1000
                          }
                          screenshotFormat="image/jpeg"
                        />
                      </>
                    )}
                    {!showTimer && !isCodingQuestion && (
                      <>
                        <div className="absolute bottom-12 left-[8px] border border-[#FFFFFF]/50 rounded-xl bg-white/20 w-[222px]">
                          <Leva hidden />
                          <Suspense fallback={<>loading...</>}>
                            <Model />
                          </Suspense>
                          {/* <Canvas
                            shadows
                            camera={{position: [0, 0, 1], fov: 20}}
                          >
                            <>
                              <Suspense fallback={<>loading...</>}>
                                <CameraControls ref={cameraControls} />
                                <Environment preset="sunset" />
                                <Model
                                  currentQuestion={currentQuestion}
                                  saveRecording={saveRecording}
                                />
                                <ContactShadows opacity={0.7} />
                              </Suspense>
                            </>
                          </Canvas> */}
                        </div>

                        <Row classNames="absolute justify-between bottom-2 left-0 w-full px-12">
                          <p className="rounded-xl py-2 px-4 text-sm bg-lightBlack-900 bg-opacity-50 text-white-200">
                            {"SourcebaeAI"}
                          </p>
                          <p className="rounded-xl py-2 px-4 text-sm bg-lightBlack-900 bg-opacity-50 text-white-200">{`${interview_details?.data.assessment.candidate.user.first_name} ${interview_details?.data.assessment.candidate.user.last_name}`}</p>
                        </Row>
                      </>
                    )}
                  </section>
                </Row>

                {!showTimer && (
                  <Row classNames={styles.questions__card}>
                    <div
                      className=" rounded-[4px] pt-[36px] px-[24px] min-w-[95%] max-w-[95%] h-full relative bg-white-900"
                      style={{
                        boxShadow: "0px 0px 22.98px 0px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      {!isCodingQuestion ? (
                        showIndividualQuestionTimer ? (
                          <Row classNames="justify-end">
                            <Row>
                              <img
                                src={Images.Sandbox}
                                className="w-8 h-8 mr-2"
                                alt="hourglass"
                              />
                              <CountDownTimer
                                time={SecondRoundPerQuestionTime}
                                sx={{
                                  color: AppColors.BLACK,
                                  fontSize: 14,
                                  fontWeight: "bolder",
                                }}
                                onStopTimer={handleQuestionChange}
                                returnUpdatedValue={(remainingTime) => {
                                  if (remainingTime === 3) {
                                    clearRecorders();
                                  }
                                }}
                              />
                            </Row>
                          </Row>
                        ) : null
                      ) : null}
                      <div className="flex justify-between items-center">
                        <div className="font-bold text-[28px]">
                          Question - {currentQuestionIndex + 1}
                        </div>
                        <div className="text-black/50">
                          {currentQuestionIndex + 1}/{allQuestions.length}
                        </div>
                      </div>
                      <div className="mt-[28px] text-base font-medium">
                        {currentQuestion?.question}
                      </div>
                      {!isCodingQuestion ? (
                        <div className="mt-[24px] text-[#1453FF] bg-[#F5F7FF] p-[20px] font-normal rounded-[16px] border border-dashed border-[#1453FF] max-h-[65%] overflow-scroll">
                          <span className="font-bold">Answer</span> - {answer}
                        </div>
                      ) : (
                        <div className="mt-3 border-2 border-[#eee] p-6 rounded-sm pb-0">
                          <Row classNames="text-sm items-start mb-4">
                            <label
                              htmlFor="language-select"
                              className="w-1/4 font-bold"
                            >
                              Select Language:{" "}
                            </label>
                            <select
                              id="language-select"
                              value={language}
                              className="w-3/4 border border-black-150 py-2 px-2 rounded-md"
                              onChange={(e) =>
                                setLanguage(Number(e.target.value))
                              }
                            >
                              {languages.map((lang) => {
                                return (
                                  <option key={lang.id} value={lang.id}>
                                    {lang.name}
                                  </option>
                                );
                              })}
                            </select>
                          </Row>
                          <p className="text-sm font-bold mb-3">
                            Expected output:{" "}
                            {`${currentQuestion?.questionAnswer}`}
                          </p>
                          <Row classNames="justify-between">
                            {codeOutput && (
                              <p className="text-sm font-bold mb-3">{`Your output: ${codeOutput}`}</p>
                            )}
                          </Row>
                          <Editor
                            height="25vh"
                            value={code}
                            onChange={(e) => setCode(e)}
                            options={{
                              minimap: {enabled: false},
                              scrollbar: {
                                vertical: "hidden",
                                horizontal: "hidden",
                              },
                              overviewRulerBorder: false,
                              renderLineHighlight: "none",
                            }}
                          />
                        </div>
                      )}
                      <div className="flex justify-end absolute bottom-2 right-4">
                        {isCodingQuestion && code && (
                          <Button
                            className="mr-3"
                            onClick={submitCode}
                            disabled={isCodeSubmitting}
                            style={{
                              background: "linear-gradient(#BC53FF, #F1A0AD)",
                            }}
                          >
                            {!isCodeSubmitting ? (
                              <>
                                Run
                                <span className="ml-1">
                                  <IoIosPlay />
                                </span>
                              </>
                            ) : (
                              <Windmill />
                            )}
                          </Button>
                        )}
                        <Button
                          onClick={handleQuestionChange}
                          variant="contained"
                          disabled={
                            isCodingQuestion
                              ? isCodeSubmitting
                              : processingResponse
                          }
                          style={{
                            background: "linear-gradient(#F1A0AD , #BC53FF)",
                            marginLeft: 50,
                            fontWeight: "bolder",
                          }}
                        >
                          {
                            <>
                              {isCodingQuestion ? "Submit code" : "Next"}
                              {!isCodingQuestion ? (
                                showIndividualQuestionTimer ? null : processingResponse ? (
                                  <Bounce className="ml-4" />
                                ) : null
                              ) : null}
                            </>
                          }
                        </Button>
                      </div>
                    </div>
                  </Row>
                )}
              </div>
            </Row>
          </div>
        </div>
        <Row
          style={{
            visibility: showToast ? "visible" : "hidden",
            transition: "all 800ms ease-out",
            opacity: showToast ? 1 : 0,
          }}
          classNames="absolute left-0 bottom-8 w-full justify-center"
        >
          <p
            className={`text-center bg-[#00D121] text-white-900 px-12 py-2 rounded-lg`}
          >
            {toastString}
          </p>
        </Row>
      </main>
    </FullScreen>
  );
};

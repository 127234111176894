import {Typography} from "@mui/material";
import {useAppCommonDataProvider} from "components/AppCommonDataProvider";
import {LeftArrow, RightArrow} from "components/Icons";
import {Row} from "components/Row";
import styles from "components/styles.module.scss";
import {Form, Formik} from "formik";
import strings from "globals/constants/strings.json";
import {WorkExperienceFormValues} from "globals/types/globalTypes";
import React, {useEffect} from "react";
import * as Yup from "yup";
import stylesCss from "./style.module.scss";

interface IProps {
  next: () => void;
  back: () => void;
}

export const WorkExperienceDetails: React.FC<IProps> = ({back, next}) => {
  const {createAssesmentValues, setCreateAssesmentValues} =
    useAppCommonDataProvider();

  const initialValues: WorkExperienceFormValues = {
    experience: createAssesmentValues?.years_of_experience || "",
    number_of_questions: createAssesmentValues?.questions || "60",
    projectDescription: "",
    projectName: "",
    jd: createAssesmentValues?.jd ?? "",
  };

  const validateSchema = Yup.object().shape({
    experience: Yup.number().required("experience is required"),
    number_of_questions: Yup.string().required(
      "Number of questions is required"
    ),
  });

  const submitForm = () => {
    if (createAssesmentValues) {
      next();
    }
  };

  useEffect(() => {
    //@ts-ignore
    setCreateAssesmentValues?.({
      ...createAssesmentValues,
      questions:
        createAssesmentValues?.questions === undefined
          ? "60"
          : createAssesmentValues.questions,
      years_of_experience:
        createAssesmentValues?.years_of_experience === undefined
          ? ""
          : createAssesmentValues?.years_of_experience,
      jd: createAssesmentValues?.jd ?? "",
    });
  }, []);

  return (
    <section className="mt-4">
      <div>
        <Typography variant="h5" fontWeight={"bold"}>
          {strings.new__candidate__experience.title}
        </Typography>
      </div>

      <Formik
        initialValues={initialValues}
        onSubmit={submitForm}
        validationSchema={validateSchema}
      >
        {({values, errors, handleSubmit, touched, setFieldValue}) => (
          <Form onSubmit={handleSubmit}>
            <section
              className={`${styles.newcandidate__experienceDetail} pl-0 max-w-full`}
            >
              <Row>
                <div className="flex flex-col flex-1">
                  <Typography>
                    {strings.new__candidate__experience.total_experience}
                  </Typography>
                  <input
                    onChange={(e) => {
                      if (isNaN(+e.target.value) === true) return;
                      setFieldValue("experience", e.target.value);
                      //@ts-ignore
                      setCreateAssesmentValues?.({
                        ...createAssesmentValues,
                        years_of_experience: e.target.value,
                      });
                    }}
                    value={values.experience}
                    className={styles.input}
                    placeholder="Experience in years"
                    maxLength={2}
                  />
                  {errors.experience && touched.experience && (
                    <span className="text-red-500 text-xs mt-2">
                      {errors.experience}
                    </span>
                  )}
                </div>

                <div className="flex-1 flex flex-col">
                  <Typography className={`font-normal text-sm`}>
                    {strings.new__candidate__experience.question_nos}
                    <span className="text-red-500">*</span>
                  </Typography>
                  <input
                    name="question"
                    id="question"
                    onChange={(e) => {
                      if (isNaN(+e.target.value) === true) return;
                      setFieldValue("number_of_questions", e.target.value);
                      //@ts-ignore
                      setCreateAssesmentValues?.({
                        ...createAssesmentValues,
                        questions: e.target.value,
                      });
                    }}
                    value={values.number_of_questions}
                    className={styles.input}
                    maxLength={3}
                  />
                </div>
              </Row>
            </section>

            <div className="mt-8">
              <Typography
                variant="h6"
                fontWeight={"bold"}
                className={styles.newcandidate__experienceTitle}
              >
                {strings.new__candidate__experience.jd}
              </Typography>
              <textarea
                className="border mt-4 w-full py-6 px-8 rounded-md min-h-72 text-black-150"
                placeholder="Enter a JD here..."
                value={values.jd}
                onChange={(e) => {
                  setFieldValue("jd", e.target.value);
                  //@ts-ignore
                  setCreateAssesmentValues?.({
                    ...createAssesmentValues,
                    jd: e.target.value,
                  });
                }}
              ></textarea>
              {/* <Typography
                variant="h6"
                fontWeight={"bold"}
                className={styles.newcandidate__experienceTitle}
              >
                {strings.new__candidate__experience.secondary__title}
              </Typography> */}
            </div>
            {/* {candidate__experience.projects.map((project, index) => (
              <div
                className={styles.newcandidate__experienceDetail}
                key={index}
              >
                <div className="flex items-center">
                  <h3 className="text-sm">Project name</h3>
                  <span
                    className={`ml-1 ${
                      index === 0 ? "hidden" : ""
                    } cursor-pointer`}
                    onClick={() => addOrRemoveField("remove", index)}
                  >
                    <RemoveCircleOutline />
                  </span>
                </div>
                <div className="flex flex-col">
                  <input
                    name="projectName"
                    id="projectName"
                    type="text"
                    placeholder="Type here..."
                    onChange={(e) =>
                      handleChangeForProjects(
                        e.target.value,
                        index,
                        "projectName",
                        setFieldValue
                      )
                    }
                    value={candidate__experience.projects[index].project_name}
                    className={styles.input}
                  />
                </div>
                <p className="text-sm mt-4">Project Description</p>
                <div className="flex flex-col">
                  <textarea
                    name="projectDescription"
                    id="projectDescription"
                    // onChange={handleChange("projectDescription")}
                    value={
                      candidate__experience.projects[index].project_description
                    }
                    onChange={(e) =>
                      handleChangeForProjects(
                        e.target.value,
                        index,
                        "projectDescription",
                        setFieldValue
                      )
                    }
                    placeholder={`Please describe the working of the company...`}
                    className={`${styles.input} py-2 min-h-100`}
                  ></textarea>
                </div>
              </div>
            ))} */}
            {/* <div className="max-w-80 mt-1">
              <div
                className={styles.add__filedContainer}
                onClick={() => addOrRemoveField("add")}
              >
                <p className="text-sm font-bold mr-1">
                  {strings.new__candidate__experience.add_project}
                </p>
                <AddCircleOutline size={20} />
              </div>
            </div> */}

            <div className="flex w-full justify-between mt-6">
              <button className={stylesCss.back_btn_wrap} onClick={back}>
                <LeftArrow className={stylesCss.back_icon} size={20} />
                <Typography className={stylesCss.back_button}>Back</Typography>
                <div className={stylesCss.back_btn_after}></div>
              </button>

              <button className={stylesCss.next_btn_wrap} type="submit">
                <div className={stylesCss.next_btn_before}></div>
                <Typography className={stylesCss.next_button}>
                  Select coding questions
                </Typography>
                <RightArrow className={stylesCss.icon} size={20} />
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </section>
  );
};

import {Typography} from "@mui/material";
import {RouteLinks} from "globals/constants/routeLinks";
import strings from "globals/constants/strings.json";
import {halfModalContainer} from "globals/constants/styles";
import {CustomModalBaseProps} from "globals/types/globalTypes";
import React from "react";
import Modal from "react-responsive-modal";

interface IProps extends CustomModalBaseProps {}

export const SecondRoundMismatch: React.FC<IProps> = ({open, onClose}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      showCloseIcon={false}
      styles={{modal: halfModalContainer}}
    >
      <Typography variant="h4" sx={{fontWeight: "bold"}}>
        {strings.uh_oh}
      </Typography>
      <ul className="list-disc mt-4">
        <Typography
          sx={{marginBottom: 1.5, fontSize: 16, fontWeight: "600"}}
          variant="h6"
        >
          You are seeing this window because of one or more of the following
          reasons:
        </Typography>
        {strings.secondRoundMismatch.map((data, index) => (
          <li key={index.toString()} className="mb-2 ml-6">
            <Typography variant="body2" sx={{fontSize: 15}}>
              {data}
            </Typography>
          </li>
        ))}

        <Typography>
          Click{" "}
          <span className="text-primary font-bold">
            <a
              href={`${process.env.REACT_APP_DOMAIN}/${RouteLinks.Candidate_Login}`}
            >
              here
            </a>
          </span>{" "}
          to go back and login again
        </Typography>
      </ul>
    </Modal>
  );
};

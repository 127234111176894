import {Button, Typography} from "@mui/material";
import {Images} from "Images";
import {useAppCommonDataProvider} from "components/AppCommonDataProvider";
import styles from "components/Containers/Cards/LoginCard/LoginCardSass.module.scss";
import {Email, Eye, EyeHidden, User} from "components/Icons";
import {AvailableAssessmentsForCandidate} from "components/Popups/AvailableAssessmentsForCandidate";
import {RoundTwoInstructions} from "components/Popups/RoundTwoInstructions";
import SizedBox from "components/SizedBox/SizedBox";
import {Formik} from "formik";
import {AppColors} from "globals/appcolors";
import {COOKIESKEYS} from "globals/constants/cookiesKeys";
import {APIEndPoints} from "globals/constants/endPoints";
import {secondRoundInstructionsQuery} from "globals/constants/queries/secondRoundInstructions";
import {RouteLinks} from "globals/constants/routeLinks";
import strings from "globals/constants/strings.json";
import {halfModalContainer} from "globals/constants/styles";
import {getContentfulData} from "globals/helpers";
import {
  AssessmentData,
  AssessmentDataResponse,
  Microcopy,
  Site,
} from "globals/types/globalTypes";
import axiosPostModule from "network/Post/AxiosPostModule";
import React, {useEffect, useRef, useState} from "react";
import {Bounce} from "react-activity";
import cookie from "react-cookies";
import Modal from "react-responsive-modal";
import {useNavigate, useSearchParams} from "react-router-dom";
import {toast} from "react-toastify";
import * as yup from "yup";

export const CandidateLogin: React.FC = () => {
  const [queryParams] = useSearchParams();
  const isRoundTwo = queryParams.get("isRoundTwo") === "true";

  const initialValues = {
    email: "",
    password: "",
    firstName: "",
    lastName: "",
  };

  const schema = yup.lazy(() =>
    yup.object().shape({
      firstName: yup.string().required("First Name is required"),
      email: yup
        .string()
        .required("Email is required")
        .email("Enter a valid email"),
      password: yup.string().required("Password is required"),
    })
  );

  const navigate = useNavigate();

  const {loading, setLoading} = useAppCommonDataProvider();

  const [showPassword, setshowPassword] = useState<boolean>(false);
  const [availableAssessments, setAvailableAssessments] = useState<
    AssessmentData[]
  >([]);
  const [isAvailableModdalAssessmentOpen, setisAvailableModdalAssessmentOpen] =
    useState<boolean>(false);
  const [selectedAssessment, setselectedAssessment] =
    useState<AssessmentData | null>(null);
  const [isRoundTwoModalOpen, setIsRoundTwoModalOpen] =
    useState<boolean>(false);
  const [navigating, setNavigating] = useState<boolean>(false);
  const [instructions, setInstructions] = useState<Microcopy[]>([]);
  const [isRoundTwoInstructionsModalOpen, setIsRoundTwoInstructionsModalOpen] =
    useState<boolean>(false);

  const secondRoundAssessmentId = useRef<number>(Number.NaN);
  const secondRoundToken = useRef<string>("");

  const handleLogin = async ({
    firstName,
    lastName,
    email,
    password,
  }: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
  }) => {
    setLoading?.(true);
    axiosPostModule(
      `${APIEndPoints.CANDIDATE_LOGIN_NODE}?isRoundTwo=${
        isRoundTwo === true ? "true" : "false"
      }`,
      {
        first_name: firstName.trim(),
        last_name: lastName.trim(),
        email: email.trim(),
        password: password.trim(),
      }
    )
      .then((res: AssessmentDataResponse) => {
        const {interview} = res.data;
        if (!isRoundTwo) {
          setisAvailableModdalAssessmentOpen(true);
          localStorage.setItem("candidateToken", res.data.Token);
          cookie.save(COOKIESKEYS.CANDIDATE_TOKEN, res.data.Token, {
            path: "/",
          });
          cookie.save("candidateID", res?.data.assesments[0].candidate.id, {
            path: "/",
          });
          setAvailableAssessments(res?.data.assesments);
        } else if (isRoundTwo) {
          if (interview) {
            localStorage.setItem("candidateToken", res.data.Token);
            cookie.save(COOKIESKEYS.CANDIDATE_TOKEN, res.data.Token, {
              path: "/",
            });
            localStorage.setItem(
              COOKIESKEYS.ROUND_TWO_QUESTIONS,
              JSON.stringify(interview[0].assessment_round_two_questions)
            );
            secondRoundAssessmentId.current = interview[0].id;
            secondRoundToken.current = res.data.Token;

            // include intro and all other keys will be fetched from interview object
            // if intro is there, proceed to instructions
            // otherwise proceed for second round

            if (
              interview[0]?.include_intro === true &&
              interview[0]?.include_round_one === true
            ) {
              setIsRoundTwoModalOpen(true);
            } else if (
              interview[0]?.include_intro === true &&
              interview[0]?.include_round_one === false
            ) {
              const {assessment_url, id, assessment_duration} = interview[0];
              navigate(
                `/${RouteLinks.Interview}/${assessment_url}/${id}?duration=${assessment_duration}`
              );
            } else {
              setIsRoundTwoInstructionsModalOpen(true);
            }
          } else {
            setisAvailableModdalAssessmentOpen(true);
            setAvailableAssessments(res?.data.assesments);
          }
        }
      })
      .catch((err) => {
        if (!err) {
          toast.error(
            "We seem to be having a problem at our end. We request you to try again after some time"
          );
          return;
        }
        if (err.status === 404) {
          toast.error(
            "Sorry, But could not find an account as per the information you provided"
          );
        }
        if (err.status === 500) {
          toast.error(
            "Don't worry. This is a problem on our end. Please try logging in later or try contacting a POC "
          );
        }
        if (err.status === 422) {
          toast.error(err.data.data[0]);
        }
      })
      .finally(() => {
        setTimeout(() => setLoading?.(false), 1000);
      });
  };

  const proceed = () => {
    const token = localStorage.getItem("candidateToken");
    if (isRoundTwo === true)
      navigate(`/${RouteLinks.Interview_Round_2}?token=${token}`);
    else
      navigate(
        `/${RouteLinks.Interview}/${selectedAssessment!.assessment_url}/${
          selectedAssessment!.id
        }?duration=${selectedAssessment!.assessment_duration}`
      );
  };

  const handleClose = () => {
    if (availableAssessments.length) return;
    else setisAvailableModdalAssessmentOpen(false);
  };

  const proceedForSecondRound = () => {
    setNavigating(true);
    setTimeout(() => setNavigating(false), 400);
    setTimeout(
      () =>
        navigate(
          `/${RouteLinks.Interview_Round_2}?assessment=${secondRoundAssessmentId.current}&token=${secondRoundToken.current}`
        ),
      450
    );
  };

  const fetchInstructions = async () => {
    const {
      data: {
        siteCollection: {items},
      },
    } = await getContentfulData<{
      siteCollection: {items: [Pick<Site, "instructionsCollection">]};
    }>(secondRoundInstructionsQuery, {
      project: process.env.REACT_APP_CONTENTFUL_PROJECT,
    });
    setInstructions(items[0].instructionsCollection.items);
  };

  useEffect(() => {
    fetchInstructions();
  }, []);

  return (
    <main
      className={styles.container}
      style={{
        backgroundImage: `url(${Images.loginBg})`,
      }}
    >
      <div className={styles.logo__container}>
        <p className="font-bold text-24">RecruitBae</p>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.card__container}>
          <SizedBox height="10px" />
          <h1 className={styles.title}>Welcome to RecruitBae</h1>
          <SizedBox height="40px" />
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={(data) => {
              handleLogin(data);
            }}
            validationSchema={schema}
          >
            {({handleSubmit, handleChange, errors, touched}) => {
              return (
                <form
                //  autoComplete="off"
                >
                  <div className={styles.form__container}>
                    <div className={styles.input__container}>
                      <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        onChange={handleChange}
                        placeholder="First Name"
                      />

                      <User
                        color={AppColors.REPORTCARD_SECONDARY}
                        size={20}
                        className={styles.user_logo}
                      />
                    </div>
                    {errors.firstName && touched.firstName && (
                      <p className={styles.error__text}>{errors?.firstName}</p>
                    )}
                    <SizedBox height="20px" />
                    <div className={styles.input__container}>
                      <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        onChange={handleChange}
                        placeholder="Last Name"
                      />

                      <User
                        color={AppColors.REPORTCARD_SECONDARY}
                        size={20}
                        className={styles.user_logo}
                      />
                    </div>
                    <SizedBox height="20px" />

                    <div className={styles.input__container}>
                      <input
                        type="text"
                        id="email"
                        name="email"
                        onChange={handleChange}
                        placeholder="Email"
                      />
                      <Email
                        color={AppColors.REPORTCARD_SECONDARY}
                        size={20}
                        className={styles.user_logo}
                      />
                    </div>
                    {errors.email && touched.email && (
                      <p className={styles.error__text}>{errors?.email}</p>
                    )}
                  </div>

                  <SizedBox height="20px" />

                  <div
                    className={styles.form__container}
                    style={{
                      marginTop: errors.email && touched.email ? "1rem" : 0,
                    }}
                  >
                    <div className={styles.input__container}>
                      <input
                        type={showPassword ? "text" : "password"}
                        id="password"
                        name="password"
                        onChange={handleChange}
                        placeholder="Password"
                      />
                      {!showPassword ? (
                        <div onClick={() => setshowPassword(!showPassword)}>
                          <Eye
                            size={23}
                            color={AppColors.REPORTCARD_SECONDARY}
                          />
                        </div>
                      ) : (
                        <div onClick={() => setshowPassword(!showPassword)}>
                          <EyeHidden
                            size={23}
                            color={AppColors.REPORTCARD_SECONDARY}
                          />
                        </div>
                      )}
                    </div>
                    {errors.password && touched.password && (
                      <p className={styles.error__text}>{errors?.password}</p>
                    )}
                  </div>
                  <SizedBox height="30px" />
                  <div className={styles.submit__buttonContainer}>
                    <button
                      className={styles.submit__button}
                      style={{background: AppColors.REPORTCARD_SECONDARY}}
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                      type="submit"
                    >
                      <div>{loading ? <Bounce /> : "Login"}</div>
                    </button>
                  </div>
                </form>
              );
            }}
          </Formik>

          {isAvailableModdalAssessmentOpen && (
            <AvailableAssessmentsForCandidate
              open={isAvailableModdalAssessmentOpen}
              onClose={handleClose}
              modalStyle={halfModalContainer}
              availableAssessments={availableAssessments.filter(
                (assessment) => assessment.status === 2
              )}
              startedAssessments={availableAssessments.filter(
                (assessment) =>
                  assessment.status === 3 || assessment.status === 12
              )}
              selectedAssessment={selectedAssessment}
              setSelectedAssessment={setselectedAssessment}
              onClickProceed={proceed}
            />
          )}

          {isRoundTwoModalOpen && (
            <RoundTwoInstructions
              open={isRoundTwoModalOpen}
              loading={navigating}
              onClose={() => setIsRoundTwoModalOpen(false)}
              onClickProceed={proceedForSecondRound}
            />
          )}

          {isRoundTwoInstructionsModalOpen && (
            <Modal
              open={isRoundTwoInstructionsModalOpen}
              onClose={() => setIsRoundTwoInstructionsModalOpen(false)}
              styles={{
                modal: {
                  ...halfModalContainer,
                  minWidth: "75vw",
                  maxWidth: "75vw",
                  maxHeight: "80vh",
                },
              }}
              center
              showCloseIcon={false}
            >
              <Typography variant="h2" sx={{fontWeight: "semibold"}}>
                {`${strings.hi}!`}
              </Typography>

              {instructions.map(({value}, index) => (
                <Typography
                  variant="subtitle1"
                  sx={{marginTop: 1, fontSize: 17, marginLeft: 3}}
                  key={index.toString()}
                >
                  {`•  ${value}`}
                </Typography>
              ))}
              <div className="flex justify-end">
                <Button
                  onClick={() =>
                    navigate(
                      `/${RouteLinks.Interview_Round_2}?assessment=${secondRoundAssessmentId.current}&token=${secondRoundToken.current}`
                    )
                  }
                  sx={{marginTop: 2, paddingX: 4}}
                >
                  Start
                </Button>
              </div>
            </Modal>
          )}
          <SizedBox height="10px" />
          <section className="absolute bottom-6 right-8">
            <div className="flex items-center">
              <p className="text-primary text-lg font-bold mr-1">Powered by</p>
              <button
                onClick={() => window.location.assign(`https://sourcebae.com`)}
              >
                <img
                  src={Images.SourceBaeLogo}
                  alt=""
                  style={{width: 35, height: 35}}
                />
              </button>
            </div>
          </section>
        </div>
      </div>
    </main>
  );
};

import {InstructionsFragment, MicroCopyFragment} from "../contentfulFragments";

export const secondRoundInstructionsQuery = `
query($project: String!) {
    siteCollection(where: {project: $project}) {
        ...on SiteCollection {
            items {
                instructionsCollection {
                    ...InstructionsFragment
                }
            }
        }
    }
}
${InstructionsFragment}
${MicroCopyFragment}
`;

import {Checkbox} from "@mui/material";
import {useAppCommonDataProvider} from "components/AppCommonDataProvider";
import {LeftArrow} from "components/Icons";
import Loader from "components/Loader";
import {Row} from "components/Row";
import {APIEndPoints} from "globals/constants/endPoints";
import {QuestionLinks} from "globals/constants/routeLinks";
import {s3Config} from "globals/constants/s3Config";
import {decodeEncodedAPIResponse, deleteAssessmentTest} from "globals/helpers";
import {
  CreateAsseessmentFields,
  CreateAssessmentCodingQuestion,
  CreateAssessmentTemplate,
  GetAllQuestionDataNode,
} from "globals/types/globalTypes";
import {useCreateAssessment} from "hooks/assessment/useCreateAssessment";
import axiosGetModule from "network/Get/AxiosGetModule";
import axiosPostModule from "network/Post/AxiosPostModule";
import {useEffect, useState} from "react";
import S3Client from "react-aws-s3-typescript";
import {toast} from "react-toastify";
import styles from "./style.module.scss";

type SelectQuestionsProps = {
  back: () => void;
  next: () => void;
};

const SelectQuestions = ({back, next}: SelectQuestionsProps) => {
  const {createAssesmentValues} = useAppCommonDataProvider();

  const [questions, setQuestions] = useState<GetAllQuestionDataNode[]>([]);
  const [selectedQuestions, setSelectedQuestions] = useState<
    GetAllQuestionDataNode[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);

  const {mutateAsync: createUserAssessment} = useCreateAssessment(
    false,
    setLoading
  );

  const disabled = selectedQuestions.length !== 2;

  const s3Client = new S3Client(s3Config);

  const fetchUserQuestions = async () => {
    const {
      user: {id},
    } = JSON.parse(localStorage.getItem("USER_DETAILS") ?? "");
    const {data, ...res} = await axiosGetModule(
      APIEndPoints.GET_QUESTIONS_BY_ID(id)
    );
    const {
      data: {data: questions},
    } = decodeEncodedAPIResponse(data, res);

    setQuestions(
      (questions as GetAllQuestionDataNode[])
        .filter((e) => createAssesmentValues?.skills.includes(e.skill_id))
        .slice(0, 9)
    );
  };

  const onChangeQuestion = (
    e: React.ChangeEvent<HTMLInputElement>,
    question: GetAllQuestionDataNode
  ) => {
    const {checked} = e.target;

    if (checked && selectedQuestions.length === 2) return;
    const exists = selectedQuestions.find((e) => e.id === question.id);

    if (exists) {
      const newList = selectedQuestions.filter((e) => e.id !== question.id);
      setSelectedQuestions(newList);
    } else {
      const newList = [...selectedQuestions, {...question}];
      setSelectedQuestions(newList);
    }
  };

  const handleEvent = (e: MessageEvent<any>) => {
    if (e.origin !== window.location.origin) return;

    window.focus();
    fetchUserQuestions();
  };

  const createTemplate = async (payload: CreateAssessmentTemplate) => {
    return await axiosPostModule(
      APIEndPoints.CREATE_ASSESSMENT_TEMPLATE_NODE,
      payload
    );
  };

  const createAssessment = async () => {
    if (createAssesmentValues) {
      setLoading(true);
      const userOrgId: any = JSON.parse(localStorage.getItem("USER_DETAILS")!);
      const allSkills = await axiosGetModule(
        APIEndPoints.GET_SKILL_WITH_QUE_COUNT_NODE
      );
      const templateName = allSkills.data.find(
        (skill: any) => skill.id === createAssesmentValues?.skills[0]
      ).skill;
      const templatePayload: CreateAssessmentTemplate = {
        organization_id: userOrgId.user.teams[0].id,
        name: templateName,
        skill_ids: createAssesmentValues?.skills,
      };
      const {data} = await createTemplate(templatePayload);

      try {
        const {
          first_name,
          last_name,
          date,
          experience_value,
          candidate_source,
          email,
          resume,
          skills,
          years_of_experience,
          questions,
          project_info,
          switch: orgOnlyQuestions,
          is_contractual,
          // new keys for contractual hiring
          include_intro,
          include_round_one,
          include_round_two,
          jd,
        } = createAssesmentValues;
        let formData = new FormData();
        formData.append("file", resume);
        const {status, location: resume_url} = await s3Client.uploadFile(
          resume,
          `${first_name}-${last_name}-resume`
        );
        if (status === 204) {
          const payload: CreateAsseessmentFields = {
            resume_url,
            assessment_template_id: data.id.toString(),
            scheduled_date: date!.format("YYYY-MM-DD"),
            candidate_name: first_name,
            candidate_last_name: last_name,
            email,
            experience_duration: years_of_experience,
            number_of_questions: questions,
            experience_level: experience_value,
            source: candidate_source,
            include_organization_questions: orgOnlyQuestions.toString(),
            organization_id: userOrgId.user.teams[0].organization.id,
            skills_ids: skills.map((skill) => skill),
            project_title:
              project_info !== undefined
                ? project_info.map((project) =>
                    project.project_name !== "" ? project.project_name : "NA"
                  )
                : ["AAA"],
            project_description:
              project_info !== undefined
                ? project_info.map((project) =>
                    project.project_description !== ""
                      ? project.project_description
                      : "NA"
                  )
                : ["AAA"],
            is_contractual,
            include_intro,
            include_round_one,
            include_round_two,
            job_description: jd,
            codingquestionanswers: selectedQuestions.map(
              ({
                is_always_included,
                question,
                question_duration,
                question_level,
                question_points,
                question_type,
                skill_id,
                question_options,
              }) => {
                return {
                  is_always_included,
                  question,
                  question_duration,
                  question_level,
                  question_points,
                  question_type,
                  skill_id,
                  options: question_options,
                } as CreateAssessmentCodingQuestion;
              }
            ),
          };

          createUserAssessment(payload).then((res) => {
            if (res !== undefined) {
              if (res.data.success) {
                toast.success("Assessment Created", {
                  position: "bottom-left",
                });
                setLoading(false);
                next();
              }
            }
          });
        }
      } catch (e) {
        setLoading(false);
        next();
        toast.error(
          "We are sorry this assessment could not be created because of a technical error on our end."
        );
        deleteAssessmentTest(data.id);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleEvent);

    fetchUserQuestions();

    return () => window.removeEventListener("message", handleEvent);
  }, []);

  useEffect(() => {
    if (createAssesmentValues) {
      setQuestions(createAssesmentValues.codingQuestions ?? []);
    }
  }, [createAssesmentValues]);

  return loading ? (
    <Loader />
  ) : (
    <div>
      <p className="mb-2 font-bold">Select 2 questions from the list</p>
      {questions.map((question) => {
        const {question: questionText, id} = question;
        return (
          <Row key={id}>
            <Checkbox
              onChange={(e) => onChangeQuestion(e, question)}
              disabled={
                !disabled &&
                !selectedQuestions.find((e) => e.id === question.id)
              }
            />
            <p className="text-sm">{questionText}</p>
          </Row>
        );
      })}

      <Row classNames={`absolute w-full bottom-4 right-0 justify-between px-4`}>
        <div className={styles.back_btn_wrap} onClick={() => back()}>
          <LeftArrow className={styles.back_icon} size={20} />
          <button className={styles.back_button}>Back</button>
          <div className={styles.back_btn_after}></div>
        </div>
        {(questions.length === 0 || questions.length === 1) && (
          <button
            className="bg-primary text-white-900 rounded-10 px-8 py-2"
            onClick={() => {
              window
                .open(
                  `${process.env.REACT_APP_DOMAIN}/${QuestionLinks.Create_Question}?isFromCreate=true`,
                  "_blank"
                )
                ?.focus();
            }}
          >
            Create Question
          </button>
        )}
        {!(questions.length === 0 || questions.length === 1) &&
          selectedQuestions.length === 2 && (
            <button
              className={`bg-primary text-white-200 rounded-10 px-8 py-2`}
              onClick={createAssessment}
            >
              Create Assessment
            </button>
          )}
      </Row>
    </div>
  );
};

export default SelectQuestions;
